.app {
  max-width: 500px;
  padding: 20px;
}

.heading {
  display: flex;
  gap: 15px;
}

.logo {
  flex-shrink: 0;
  height: 100px;
}

.list {
  padding: 0;
}

.image {
  max-width: 100%;
}
