.todo {
  display: flex;
  gap: 15px;
}

.todo:not(:last-child) {
  margin-bottom: 10px;
}

.input {
  flex: 1;
}

.delete {
  flex-shrink: 0;
}
