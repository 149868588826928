@font-face {
  font-family: "GT America";
  font-style: normal;
  font-weight: 900;
  src: url("https://slice-web-assets.prod.slicelife.com/fonts/GT-America/GT-America-Extended-Black.woff2")
    format("woff2");
}

@font-face {
  font-family: "GT America";
  font-style: normal;
  font-weight: 500;
  src: url("https://slice-web-assets.prod.slicelife.com/fonts/GT-America/GT-America-Standard-Medium.woff2")
    format("woff2");
}

@font-face {
  font-family: "GT America";
  font-style: normal;
  font-weight: 400;
  src: url("https://slice-web-assets.prod.slicelife.com/fonts/GT-America/GT-America-Standard-Regular.woff2")
    format("woff2");
}

body {
  background-color: white;
  color: midnightblue;
  font-family: "GT America", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
}
